import React from 'react'
import italy from '../images/italy.svg'
import usa from '../images/usa.svg'
export default (props) => {

	const origins = props.origins ? props.origins : null;

	const details = {
		it: {
			name: "Italy",
			id: "it",
			src: italy
		},
		us: {
			name: "USA",
			id: "us",
			src: usa
		}
	};
	if (origins) {
		return (
			<div className="flex w-full">
				<p className="mr-2 text-sm text-brand-700 ">Made in</p>
				{origins.map((origin, index) => (
					<div key={details[origin].id} className="flex items-center">
						{index > 0 && <p className="mx-2">&</p>}
						<div className="flex items-center">
							<img className="w-4 h-4 mr-1" src={details[origin].src} alt={details[origin].name} />
							<p className="text-sm text-brand-700 ">{details[origin].name}</p>
						</div>
					</div>
				))}
			</div>
		)
	}
	return null
}