import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import moment from 'moment'

export default (props) => {

  const { questions } = props



  return (
    <div className="container">
      {questions && questions.map((question) => (
        <div className="mb-6 w-full" key={question.id}>
          <div className="flex">
            <p className="mr-4 font-bold text-xs w-16">Question:</p><p className="text-sm text-brand-500 font-bold w-full">{question.question.question}</p>
          </div>
          {question.answers.map(answer => (
              <div key={answer.id} className="flex">
              <p className="mr-4 font-bold text-xs w-16">Answer:</p><p className="text-sm text-brand-500 w-full">{answer.answer.answer}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}
