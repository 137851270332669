import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

export default (props) => {
  const data = useStaticQuery(graphql`
      query {
          allFile(filter: {name: {regex: "/rating/"}}) {
            edges {
              node {
                id
                name
                relativePath
                publicURL
              }
            }
          }
      }
    `
  )
  const ratingSvg = data.allFile ? data.allFile.edges : null
  let svg = (score) => {
    return ratingSvg.filter(({ node: svg }) => {
      return svg.name === 'rating-' + score
    })[0].node.publicURL
  }

  const { reviews } = props


  // console.log('reviews', reviews)

  return (
    <div className="container">
      { reviews.map((review) => (
        <div className="mb-6 w-full" key={review.id}>
          {/* {console.log('Mapped review', review.photos)} */}
          {review.photos && <Img className="w-32 h-32 rounded mb-2" fluid={review.photos[0].localFile.childImageSharp.fluid} />}
          <img src={svg(review.score)} className="-ml-1 w-20 " alt={"Rated " + review.score + " stars"} />
          <h3 className="text-sm text-brand-700 mb-1 font-semibold">{review.title}</h3>
          <p className="text-xs text-brand-600">{review.content.content}</p>
        </div>
      ))}
    </div>
  )
}
