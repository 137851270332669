import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "./layout"
import SEO from "./seo"
import Gallery from "./gallery"
import Skus from './skus'
import Add from './add'
import Origins from './origins'
import Reviews from './reviews'
import Questions from './questions'


export default (props) => {

  const data = useStaticQuery(graphql`
      query {
          allFile(filter: {name: {regex: "/rating/"}}) {
            edges {
              node {
                id
                name
                relativePath
                publicURL
              }
            }
          }

          allContentfulProduct {
              edges {
                node {
                  id
                  inStock
                  priceInCents
                  name
                  insides
                  description {
                    description
                  }
                  photos {
                    id
                    description
                    localFile {
                      url
                      childImageSharp {
                        fluid(maxWidth: 1024) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                  origins
                  reviews {
                    id
                    title
                    score
                    content {
                      content
                    }
                    photos {
                      id
                      description
                      localFile {
                        url
                        childImageSharp {
                          fluid(maxWidth: 1024) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                  }
                  stripeSku
                  questions {
                    id
                    question {
                      question
                    }
                    answers {
                      id
                      updatedAt
                      answer {
                        answer
                      }
                    }
                  }
                  id
                  priceInCents
                }
              }
            }

          }
      `)

  const ratingSvg = data.allFile ? data.allFile.edges : null


  const items = data.allContentfulProduct.edges
  const currentItem = items.filter(({ node: item }) => { return item.id === props.pageContext.itemId });
  const title = currentItem && currentItem.length > 0 ? currentItem[0].node.name : ''
  const description = currentItem && currentItem.length > 0 ? currentItem[0].node.description.description : ''

  let hasQuestions = () => {
    if (currentItem[0].node.questions !== null) {
      return currentItem[0].node.questions.length
    }
    return 0

  }

  let getReviews = () => {
    if (currentItem[0].node.reviews !== null) {
      return currentItem[0].node.reviews
    }
    return 0
  }

  let averageRating = () => {
    if (currentItem[0].node.reviews !== null) {
      let sum = getReviews().reduce((acc, curr) => {
        return acc += curr.score
      }, 0)
      let average = sum / currentItem[0].node.reviews.length
      return Math.round(average * 2) / 2;
    }
    return 0
  }
  currentItem[0].node['type'] = 'product'
  const schemaProduct = currentItem[0].node

  return (
    <Layout>
      <SEO schemaData={schemaProduct} title={title} description={description} />

      <div className="flex bg-white">
        <div className="container mx-auto flex flex-col lg:flex-row  py-6 lg:py-0 px-4 lg:px-0">
          {currentItem.map(({ node: item }, index) => (
            <div key={item.id} className="w-full lg:w-1/2">
              <div className="block lg:hidden">
                <div className="flex flex-col lg:flex-row">
                  <div className="flex flex-row">
                    <div className="flex items-center text-red-500 mr-2">
                      <svg className="fill-current h-4 w-4 -ml-1 mr-1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M11.1756 11.53C11.7221 10.763 11.6611 9.9553 11.2822 8.50235C10.5328 5.62883 10.8312 4.05383 13.4146 2.18916L14.6755 1.27905L14.9804 2.80389C15.3045 4.42441 15.8368 5.42671 17.2034 7.35465C17.2577 7.43122 17.2577 7.43122 17.3122 7.50815C19.2808 10.2854 19.9998 11.9596 19.9998 15C19.9998 18.6884 16.2712 22 11.9998 22C7.72825 22 3.99984 18.6888 3.99984 15C3.99984 14.9311 3.99991 14.9331 3.98823 14.6285C3.89787 12.2718 4.33365 10.4274 6.0969 8.43586C6.46945 8.01509 6.89292 7.61068 7.36946 7.22371L8.42145 6.36946L8.9275 7.62658C9.30142 8.55547 9.73953 9.28567 10.2344 9.82151C10.6536 10.2754 10.9646 10.8461 11.1756 11.53ZM7.59433 9.76167C6.23696 11.2947 5.91425 12.6606 5.98676 14.5518C6.00026 14.9039 5.99984 14.8915 5.99984 15C5.99984 17.5279 8.78344 20 11.9998 20C15.216 20 17.9998 17.5275 17.9998 15C17.9998 12.4582 17.4316 11.135 15.6806 8.66471C15.6263 8.58819 15.6263 8.58819 15.5718 8.51126C14.5084 7.01112 13.8745 5.9676 13.4552 4.80053C12.7703 5.62119 12.8106 6.4374 13.2175 7.99767C13.9668 10.8712 13.6685 12.4462 11.0851 14.3109L9.61211 15.3741L9.50169 13.5608C9.43114 12.4023 9.16891 11.6156 8.76523 11.1785C8.36641 10.7467 8.00631 10.2412 7.68339 9.6628C7.65327 9.69567 7.62358 9.72862 7.59433 9.76167Z" />
                      </svg>
                      <p className="text-sm text-gray-700 font-bold">Popular</p>
                    </div>
                    <div className="flex items-center text-sm bg-green-200 text-green-800 px-2 rounded mr-2">
                      <svg className="fill-current w-4 h-4 mr-1" viewBox="0 0 24 24">
                        <path d="M3,13.5L2.25,12H7.5L6.9,10.5H2L1.25,9H9.05L8.45,7.5H1.11L0.25,6H4A2,2 0 0,1 6,4H18V8H21L24,12V17H22A3,3 0 0,1 19,20A3,3 0 0,1 16,17H12A3,3 0 0,1 9,20A3,3 0 0,1 6,17H4V13.5H3M19,18.5A1.5,1.5 0 0,0 20.5,17A1.5,1.5 0 0,0 19,15.5A1.5,1.5 0 0,0 17.5,17A1.5,1.5 0 0,0 19,18.5M20.5,9.5H18V12H22.46L20.5,9.5M9,18.5A1.5,1.5 0 0,0 10.5,17A1.5,1.5 0 0,0 9,15.5A1.5,1.5 0 0,0 7.5,17A1.5,1.5 0 0,0 9,18.5Z" />
                      </svg>
                      <p className="text-xs text-green-800 font-bold">Free Shipping</p>
                    </div>
                  </div>
                  <div className="flex items-center mr-2">
                    <Origins origins={item.origins} />
                  </div>
                </div>
                <h1 className="font-bold text-lg lg:text-2xl">{item.name}</h1>
                <div className="mb-2 flex items-center -ml-1">
                  <div className="flex items-center mr-4">
                    <img
                      src={ratingSvg.filter(({ node: svg }) => {
                        return svg.name === 'rating-' + averageRating()
                      })[0].node.publicURL}
                      alt={"Rated " + averageRating() + " stars based on " + averageRating().count + " reviews."} />

                    {getReviews().length > 0
                      ? <p className="text-gray-700 text-sm"> ({getReviews().length})</p>
                      : <p className="text-gray-700 text-sm border-b border-gray-200 border-dotted">No reviews yet</p>}
                  </div>
                  <div className="text-gray-700 flex items-center">
                    <svg width="18" height="18" viewBox="0 0 18 18" className="fill-current"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M9 16.5C10.2934 16.5 11.5403 16.1715 12.6446 15.5563L15.821 15.821L15.5563 12.6446C16.1715 11.5403 16.5 10.2934 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5ZM12.0862 14.1466L12.2937 14.0219L14.179 14.179L14.0219 12.2937L14.1466 12.0862C14.7023 11.1618 15 10.1037 15 9C15 5.68629 12.3137 3 9.00001 3C5.6863 3 3 5.68629 3 9C3 12.3137 5.6863 15 9.00001 15C10.1037 15 11.1618 14.7023 12.0862 14.1466ZM9.00025 12.7488C9.4146 12.7488 9.7505 12.413 9.7505 11.9988C9.7505 11.5845 9.4146 11.2488 9.00025 11.2488C8.5859 11.2488 8.25001 11.5845 8.25001 11.9988C8.25001 12.413 8.5859 12.7488 9.00025 12.7488ZM8.25 10.5H9.75V9.75C9.75 9.75183 9.75354 9.74793 9.76157 9.73908C9.78125 9.7174 9.82789 9.666 9.91537 9.59661C9.99049 9.53701 10.0138 9.52157 10.2036 9.40123C10.8495 8.99174 11.25 8.27967 11.25 7.5C11.25 6.25736 10.2426 5.25 9 5.25C7.75736 5.25 6.75 6.25736 6.75 7.5H8.25C8.25 7.08579 8.58579 6.75 9 6.75C9.41421 6.75 9.75 7.08579 9.75 7.5C9.75 7.76049 9.6169 7.99715 9.40047 8.13436C9.15762 8.28831 9.11731 8.31503 8.98312 8.42149C8.53705 8.77536 8.25 9.19702 8.25 9.75V10.5Z" />
                    </svg>
                    <p className="ml-1 text-gray-700">{
                      hasQuestions() > 0
                        ? hasQuestions() > 1
                          ? ' Questions answered'
                          : ' Question answered'
                        : 'No Question answered'
                    }</p>
                  </div>
                </div>
                <div className="max-w-sm mt-2 mb-2 lg:mt-4 lg:mb-6" method="post" action="">
                  <div className="bg-gray-200 inline-block rounded  flex items-center justify-between">
                    <p className="font-bold text-center block w-1/3 text-sm">${(item.priceInCents / 100).toFixed(2)}</p>
                    <Add item={item} />
                  </div>
                </div>
              </div>
              <div className="w:full md:mx-auto">
                <Gallery photos={item.photos} />
              </div>
            </div>
          ))}
          <div className="w-full lg:w-1/2">
            {currentItem.map(({ node: item }) => (
              <div key={item.id}>
                <div className="hidden lg:block">
                  <div className="flex">
                    <div className="flex items-center text-red-500 mr-2">
                      <svg className="fill-current h-4 w-4 -ml-1 mr-1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M11.1756 11.53C11.7221 10.763 11.6611 9.9553 11.2822 8.50235C10.5328 5.62883 10.8312 4.05383 13.4146 2.18916L14.6755 1.27905L14.9804 2.80389C15.3045 4.42441 15.8368 5.42671 17.2034 7.35465C17.2577 7.43122 17.2577 7.43122 17.3122 7.50815C19.2808 10.2854 19.9998 11.9596 19.9998 15C19.9998 18.6884 16.2712 22 11.9998 22C7.72825 22 3.99984 18.6888 3.99984 15C3.99984 14.9311 3.99991 14.9331 3.98823 14.6285C3.89787 12.2718 4.33365 10.4274 6.0969 8.43586C6.46945 8.01509 6.89292 7.61068 7.36946 7.22371L8.42145 6.36946L8.9275 7.62658C9.30142 8.55547 9.73953 9.28567 10.2344 9.82151C10.6536 10.2754 10.9646 10.8461 11.1756 11.53ZM7.59433 9.76167C6.23696 11.2947 5.91425 12.6606 5.98676 14.5518C6.00026 14.9039 5.99984 14.8915 5.99984 15C5.99984 17.5279 8.78344 20 11.9998 20C15.216 20 17.9998 17.5275 17.9998 15C17.9998 12.4582 17.4316 11.135 15.6806 8.66471C15.6263 8.58819 15.6263 8.58819 15.5718 8.51126C14.5084 7.01112 13.8745 5.9676 13.4552 4.80053C12.7703 5.62119 12.8106 6.4374 13.2175 7.99767C13.9668 10.8712 13.6685 12.4462 11.0851 14.3109L9.61211 15.3741L9.50169 13.5608C9.43114 12.4023 9.16891 11.6156 8.76523 11.1785C8.36641 10.7467 8.00631 10.2412 7.68339 9.6628C7.65327 9.69567 7.62358 9.72862 7.59433 9.76167Z" />
                      </svg>
                      <p className="text-sm text-gray-700 font-bold">Popular</p>
                    </div>
                    <div className="flex items-center text-sm bg-green-200 text-green-800 px-2 rounded mr-2">
                      <svg className="fill-current w-4 h-4 mr-1" viewBox="0 0 24 24">
                        <path d="M3,13.5L2.25,12H7.5L6.9,10.5H2L1.25,9H9.05L8.45,7.5H1.11L0.25,6H4A2,2 0 0,1 6,4H18V8H21L24,12V17H22A3,3 0 0,1 19,20A3,3 0 0,1 16,17H12A3,3 0 0,1 9,20A3,3 0 0,1 6,17H4V13.5H3M19,18.5A1.5,1.5 0 0,0 20.5,17A1.5,1.5 0 0,0 19,15.5A1.5,1.5 0 0,0 17.5,17A1.5,1.5 0 0,0 19,18.5M20.5,9.5H18V12H22.46L20.5,9.5M9,18.5A1.5,1.5 0 0,0 10.5,17A1.5,1.5 0 0,0 9,15.5A1.5,1.5 0 0,0 7.5,17A1.5,1.5 0 0,0 9,18.5Z" />
                      </svg>
                      <p className="text-xs text-green-800 font-bold">Free Shipping</p>
                    </div>
                    <div className="flex items-center mr-2">
                      <Origins origins={item.origins} />
                    </div>
                  </div>
                  <h1 className="font-bold text-lg lg:text-2xl">{item.name}</h1>
                  <div className="mb-2 flex items-center -ml-1">
                    <div className="flex items-center mr-4">
                      <img
                        src={
                          ratingSvg.filter(({ node: svg }) => {
                          return svg.name === ('rating-' + averageRating())
                        })[0].node.publicURL
                      }
                        alt={"Rated " + averageRating() + " stars based on " + averageRating().count + " reviews."} />

                      {getReviews().length > 0
                        ? <p className="text-gray-700 text-sm"> ({getReviews().length})</p>
                        : <p className="text-gray-700 text-sm border-b border-gray-200 border-dotted">No reviews yet</p>}
                    </div>

                    <div className="text-gray-700 flex items-center">
                      <svg width="18" height="18" viewBox="0 0 18 18" className="fill-current"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9 16.5C10.2934 16.5 11.5403 16.1715 12.6446 15.5563L15.821 15.821L15.5563 12.6446C16.1715 11.5403 16.5 10.2934 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5ZM12.0862 14.1466L12.2937 14.0219L14.179 14.179L14.0219 12.2937L14.1466 12.0862C14.7023 11.1618 15 10.1037 15 9C15 5.68629 12.3137 3 9.00001 3C5.6863 3 3 5.68629 3 9C3 12.3137 5.6863 15 9.00001 15C10.1037 15 11.1618 14.7023 12.0862 14.1466ZM9.00025 12.7488C9.4146 12.7488 9.7505 12.413 9.7505 11.9988C9.7505 11.5845 9.4146 11.2488 9.00025 11.2488C8.5859 11.2488 8.25001 11.5845 8.25001 11.9988C8.25001 12.413 8.5859 12.7488 9.00025 12.7488ZM8.25 10.5H9.75V9.75C9.75 9.75183 9.75354 9.74793 9.76157 9.73908C9.78125 9.7174 9.82789 9.666 9.91537 9.59661C9.99049 9.53701 10.0138 9.52157 10.2036 9.40123C10.8495 8.99174 11.25 8.27967 11.25 7.5C11.25 6.25736 10.2426 5.25 9 5.25C7.75736 5.25 6.75 6.25736 6.75 7.5H8.25C8.25 7.08579 8.58579 6.75 9 6.75C9.41421 6.75 9.75 7.08579 9.75 7.5C9.75 7.76049 9.6169 7.99715 9.40047 8.13436C9.15762 8.28831 9.11731 8.31503 8.98312 8.42149C8.53705 8.77536 8.25 9.19702 8.25 9.75V10.5Z" />
                      </svg>
                      <p className="ml-1 text-gray-700">{
                        hasQuestions() > 0
                          ? hasQuestions() > 1
                            ? hasQuestions() + ' Questions answered'
                            : hasQuestions() + ' Question answered'
                          : 'No Question answered'
                      }</p>
                    </div>
                  </div>
                  <div className="max-w-sm mt-2 mb-2 lg:mt-4 lg:mb-6">
                    <div className="bg-gray-200 inline-block rounded  flex items-center justify-between">
                      <p className="font-bold text-center block w-1/3 text-sm">${(item.priceInCents / 100).toFixed(2)}</p>
                      <Add item={item} />
                    </div>
                  </div>
                </div>
                <p className="mb-4">{item.name}</p>
                <p className="mb-4">{item.description.description !== null ? item.description.description : 'This is the description for: ' + item.name}</p>
                <p className="font-bold text-gray-800 mb-2">What's inside?</p>
                <ul className="mb-4">
                  {item.insides.map((whatsInside, index) => (
                    <li key={item.id + index} className="mb-2">{whatsInside}</li>
                  ))}

                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="container mx-auto">

        <div className="flex flex lg:flex-row">
          {currentItem[0].node.questions &&
            <div className="flex flex-col py-6 px-2 lg:w-1/2 pr-8">
              <p className="font-semibold text-gray-700 text-xl text-brand-600 mb-6">Customer questions & answers</p>
              <Questions questions={currentItem[0].node.questions} />
            </div>
          }
          {currentItem[0].node.reviews &&
            <div className="flex flex-col py-6 px-2 lg:w-1/2 pr-8">
              <p className="font-semibold text-gray-700 text-xl text-brand-600 mb-6">Customer reviews</p>
              <Reviews reviews={currentItem[0].node.reviews} />
            </div>
          }
        </div>
        <div className="flex items-center">
          <div className="-ml-16 mr-4">
            <svg width="52" height="19" viewBox="0 0 52 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="52" width="19" height="13" transform="rotate(90 52 0)" fill="#093544" />
              <rect x="33" width="19" height="14" transform="rotate(90 33 0)" fill="#6EAD4D" />
              <rect x="13" width="19" height="13" transform="rotate(90 13 0)" fill="#627C86" />
            </svg>
          </div>
          <p className="font-bold text-gray-700">Other items to consider</p>
        </div>
        <div className="flex flex-wrap py-6">
          <Skus size="sm" exclude={currentItem[0].node.id} />
        </div>

      </div>
    </Layout>
  )
}

